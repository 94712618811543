import React from 'react';

const Blog = () => {
  const blogPosts = [
    { id: 1, title: 'The Future of AI in Business', date: '2023-05-15', excerpt: 'Exploring how AI is shaping the future of business operations and decision-making.' },
    { id: 2, title: 'Ethical Considerations in AI Development', date: '2023-05-10', excerpt: 'Discussing the importance of ethics in AI and how to implement ethical guidelines.' },
    { id: 3, title: 'AI and Data Privacy: Striking the Right Balance', date: '2023-05-05', excerpt: 'Examining the challenges and solutions for maintaining data privacy in AI systems.' },
  ];

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Adapty AI Blog</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>Stay updated with the latest insights and developments in the world of AI.</p>
      <div style={{ display: 'grid', gap: '2rem' }}>
        {blogPosts.map(post => (
          <div key={post.id} style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>{post.title}</h3>
            <p style={{ fontSize: '0.9rem', color: '#666', marginBottom: '1rem' }}>Published on {post.date}</p>
            <p>{post.excerpt}</p>
            <a href={`/blog/${post.id}`} style={{ color: '#3498db', textDecoration: 'none', fontWeight: 'bold', marginTop: '1rem', display: 'inline-block' }}>Read More</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
