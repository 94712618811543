import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Alfi from './components/Alfi';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Products from './pages/Products';
import AIDemos from './pages/AIDemos';
import AIStore from './pages/AIStore';  // Import the AI Store page
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';  
import PasswordReset from './pages/PasswordReset';  
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <Header />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/products" element={<Products />} />
              <Route path="/ai-demos" element={<AIDemos />} />
              <Route path="/ai-store" element={<AIStore />} />  {/* Add AI Store route */}
              <Route path="/blog" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/profile" element={<Profile />} />  
              <Route path="/password-reset" element={<PasswordReset />} />  
            </Routes>
          </main>
          <Alfi />
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
