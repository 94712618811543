import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#34495e', color: 'white', padding: '2rem 0', marginTop: '2rem' }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexWrap: 'wrap' }}>
        <div>
          <h3 style={{ marginBottom: '1rem' }}>Adapty AI</h3>
          <p>Revolutionizing AI-driven applications</p>
        </div>
        <div>
          <h4 style={{ marginBottom: '1rem' }}>Quick Links</h4>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><Link to="/" style={{ color: 'white', textDecoration: 'none' }}>Home</Link></li>
            <li><Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>About</Link></li>
            <li><Link to="/services" style={{ color: 'white', textDecoration: 'none' }}>Services</Link></li>
            <li><Link to="/products" style={{ color: 'white', textDecoration: 'none' }}>Products</Link></li>
          </ul>
        </div>
        <div>
          <h4 style={{ marginBottom: '1rem' }}>Contact</h4>
          <p>Email: info@adaptyai.com</p>
          <p>Phone: (123) 456-7890</p>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <p>&copy; 2023 Adapty AI. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
