import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Header = () => {
  const { currentUser } = useAuth();

  return (
    <header style={{ backgroundColor: '#2c3e50', color: 'white', padding: '1rem' }}>
      <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '1200px', margin: '0 auto' }}>
        <Link to="/" style={{ color: 'white', textDecoration: 'none', fontSize: '1.5rem' }}>Adapty AI</Link>
        <ul style={{ listStyle: 'none', display: 'flex', gap: '1rem' }}>
          <li><Link to="/" style={{ color: 'white', textDecoration: 'none' }}>Home</Link></li>
          <li><Link to="/about" style={{ color: 'white', textDecoration: 'none' }}>About</Link></li>
          <li><Link to="/services" style={{ color: 'white', textDecoration: 'none' }}>Services</Link></li>
          <li><Link to="/products" style={{ color: 'white', textDecoration: 'none' }}>Products</Link></li>
          <li><Link to="/ai-demos" style={{ color: 'white', textDecoration: 'none' }}>AI Demos</Link></li>
          <li><Link to="/ai-store" style={{ color: 'white', textDecoration: 'none' }}>AI Store</Link></li>
          <li><Link to="/blog" style={{ color: 'white', textDecoration: 'none' }}>Blog</Link></li>
          <li><Link to="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contact</Link></li>
          {currentUser ? (
            <>
              <li><Link to="/dashboard" style={{ color: 'white', textDecoration: 'none' }}>Dashboard</Link></li>
              <li><Link to="/profile" style={{ color: 'white', textDecoration: 'none' }}>Profile</Link></li>
            </>
          ) : (
            <>
              <li><Link to="/login" style={{ color: 'white', textDecoration: 'none' }}>Login</Link></li>
              <li><Link to="/signup" style={{ color: 'white', textDecoration: 'none' }}>Sign Up</Link></li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
