import React from 'react';
import { Link } from 'react-router-dom';

const Products = () => {
  const products = [
    { id: 1, name: 'AI Text Generator Pro', description: 'Advanced AI-powered text generation tool for content creators and marketers.' },
    { id: 2, name: 'VoiceAI Assistant', description: 'Personal AI voice assistant for task management and scheduling.' },
    { id: 3, name: 'ImageAI Enhancer', description: 'AI-powered image enhancement and editing tool.' },
    { id: 4, name: 'DataAI Analyzer', description: 'Comprehensive data analysis and visualization platform.' },
  ];

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Our Products</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>
        Discover our range of AI-powered products designed to enhance productivity and creativity.
      </p>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
        {products.map(product => (
          <div key={product.id} style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>{product.name}</h3>
            <p style={{ marginBottom: '1rem' }}>{product.description}</p>
            <Link to={`/products/${product.id}`} style={{ backgroundColor: '#3498db', color: 'white', padding: '0.5rem 1rem', borderRadius: '4px', textDecoration: 'none', display: 'inline-block' }}>Learn More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
