import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const featuredProducts = [
    { id: 1, name: 'AI Text Generator Pro', description: 'Advanced AI-powered text generation tool for content creators and marketers.' },
    { id: 2, name: 'VoiceAI Assistant', description: 'Personal AI voice assistant for task management and scheduling.' },
    { id: 3, name: 'ImageAI Enhancer', description: 'AI-powered image enhancement and editing tool.' },
  ];

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Welcome to Adapty AI</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>Revolutionizing AI-driven applications with a focus on ethical usage, compliance, and sustainability.</p>

      <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Featured Products</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
        {featuredProducts.map(product => (
          <div key={product.id} style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>{product.name}</h3>
            <p style={{ marginBottom: '1rem' }}>{product.description}</p>
            <Link to={`/products/${product.id}`} style={{ backgroundColor: '#3498db', color: 'white', padding: '0.5rem 1rem', borderRadius: '4px', textDecoration: 'none', display: 'inline-block' }}>View Product</Link>
          </div>
        ))}
      </div>

      <div style={{ marginTop: '3rem', textAlign: 'center' }}>
        <Link to="/ai-demos" style={{ backgroundColor: '#2ecc71', color: 'white', padding: '1rem 2rem', borderRadius: '4px', textDecoration: 'none', fontSize: '1.2rem', display: 'inline-block' }}>
          Try Our AI Demos
        </Link>
      </div>

      <div style={{ marginTop: '3rem' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Why Choose Adapty AI?</h2>
        <ul style={{ fontSize: '1.1rem', paddingLeft: '1.5rem' }}>
          <li>Cutting-edge AI technology</li>
          <li>Ethical AI development practices</li>
          <li>Customizable solutions for your business needs</li>
          <li>Excellent customer support</li>
          <li>Continuous innovation and improvement</li>
        </ul>
      </div>

      <div style={{ marginTop: '3rem' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Latest from Our Blog</h2>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
          <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '0.5rem' }}>The Future of AI in Business</h3>
            <p style={{ marginBottom: '1rem' }}>Explore how AI is transforming various industries and what it means for your business.</p>
            <Link to="/blog/1" style={{ color: '#3498db', textDecoration: 'none' }}>Read More</Link>
          </div>
          <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ fontSize: '1.3rem', marginBottom: '0.5rem' }}>Ethical AI: A Guide for Developers</h3>
            <p style={{ marginBottom: '1rem' }}>Learn about the importance of ethics in AI development and how to implement ethical guidelines.</p>
            <Link to="/blog/2" style={{ color: '#3498db', textDecoration: 'none' }}>Read More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
