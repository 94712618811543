import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

const AIDemos = () => {
  const [demoResult, setDemoResult] = useState('');
  const [imageFile, setImageFile] = useState(null);

  const runDemo = async (demoType) => {
    setDemoResult('Running demo...');

    if (demoType === 'Text Generation') {
      try {
        const response = await axios.post('https://api-inference.huggingface.co/models/gpt2', {
          inputs: 'Once upon a time',
        }, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_HUGGINGFACE_API_KEY}`
          }
        });

        setDemoResult(response.data[0].generated_text);
      } catch (error) {
        setDemoResult('Failed to run demo');
        console.error('Error running text generation demo:', error);
      }
    } else if (demoType === 'Image Recognition' && imageFile) {
      try {
        const formData = new FormData();
        formData.append('file', imageFile);

        const response = await axios.post('https://api-inference.huggingface.co/models/google/vit-base-patch16-224', formData, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_HUGGINGFACE_API_KEY}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        setDemoResult(response.data);
      } catch (error) {
        setDemoResult('Failed to run demo');
        console.error('Error running image recognition demo:', error);
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    setImageFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>AI Demos</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>Try out our AI-powered demos below.</p>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
        <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem' }}>
          <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Text Generation Demo</h3>
          <button onClick={() => runDemo('Text Generation')} style={{ backgroundColor: '#3498db', color: 'white', padding: '0.5rem 1rem', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>Run Demo</button>
        </div>
        <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem' }}>
          <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Image Recognition Demo</h3>
          <div {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '1rem', textAlign: 'center' }}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop an image here, or click to select one</p>
          </div>
          {imageFile && <p style={{ marginTop: '1rem' }}>Selected file: {imageFile.name}</p>}
          <button onClick={() => runDemo('Image Recognition')} style={{ backgroundColor: '#3498db', color: 'white', padding: '0.5rem 1rem', borderRadius: '4px', border: 'none', cursor: 'pointer', marginTop: '1rem' }}>Run Demo</button>
        </div>
      </div>
      {demoResult && (
        <div style={{ marginTop: '2rem', padding: '1rem', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
          <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Demo Result</h3>
          <pre>{JSON.stringify(demoResult, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default AIDemos;
