import React from 'react';

const Services = () => {
  const services = [
    { id: 1, name: 'AI Consultation', description: 'Expert guidance on integrating AI into your business processes.' },
    { id: 2, name: 'Custom AI Development', description: 'Tailored AI solutions designed to meet your specific needs.' },
    { id: 3, name: 'AI Model Training', description: 'High-quality data preparation and model training services.' },
    { id: 4, name: 'AI Ethics Auditing', description: 'Comprehensive review of AI systems for ethical compliance.' },
  ];

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Our Services</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>
        Adapty AI offers a range of services to help businesses leverage the power of artificial intelligence ethically and effectively.
      </p>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
        {services.map(service => (
          <div key={service.id} style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>{service.name}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
