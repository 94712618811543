import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      console.log('No current user, redirecting to login...');
      navigate('/login'); // Redirect to login if not authenticated
    } else {
      console.log('User is authenticated:', currentUser);
      setLoading(false);
    }
  }, [currentUser, navigate]);

  const handleLogout = async () => {
    try {
      await logout();
      console.log('User logged out, redirecting to AI Store...');
      navigate('/ai-store');  // Redirect to AI Store after logout
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>; // Display loading state while checking authentication
  }

  if (!currentUser) {
    return null; // Return null if currentUser is still undefined
  }

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Dashboard</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>Welcome, {currentUser.email}!</p>
      <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', marginBottom: '2rem' }}>
        <h2 style={{ fontSize: '1.8rem', marginBottom: '1rem' }}>Your AI Usage</h2>
        <p>AI Text Generator Pro: 50 uses this month</p>
        <p>VoiceAI Assistant: 30 minutes used</p>
        <p>ImageAI Enhancer: 100 images processed</p>
      </div>
      <div style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', marginBottom: '2rem' }}>
        <h2 style={{ fontSize: '1.8rem', marginBottom: '1rem' }}>Recent Activity</h2>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>Generated 5 blog posts using AI Text Generator Pro</li>
          <li>Scheduled 3 meetings with VoiceAI Assistant</li>
          <li>Enhanced 20 product images with ImageAI Enhancer</li>
        </ul>
      </div>
      <button
        onClick={handleLogout}
        style={{ backgroundColor: '#e74c3c', color: 'white', padding: '0.5rem 1rem', borderRadius: '4px', border: 'none', cursor: 'pointer', fontSize: '1rem' }}
      >
        Log Out
      </button>
    </div>
  );
};

export default Dashboard;
