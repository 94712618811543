import React from 'react';

const About = () => {
  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>About Adapty AI</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>
        Adapty AI is at the forefront of ethical AI development, focusing on creating intelligent solutions that respect user privacy and promote sustainability.
      </p>
      <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Our Mission</h2>
      <p style={{ fontSize: '1.1rem', marginBottom: '2rem' }}>
        To revolutionize the AI industry by developing cutting-edge, ethical AI solutions that enhance human capabilities while prioritizing privacy, transparency, and environmental responsibility.
      </p>
      <h2 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Our Values</h2>
      <ul style={{ fontSize: '1.1rem', marginLeft: '2rem' }}>
        <li>Ethics-First Approach</li>
        <li>Continuous Innovation</li>
        <li>User Privacy Protection</li>
        <li>Environmental Sustainability</li>
        <li>Transparency and Accountability</li>
      </ul>
    </div>
  );
};

export default About;
