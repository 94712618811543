import React from 'react';

const AIStore = () => {
  const products = [
    { id: 1, name: 'AI Text Generator Pro', price: 49.99 },
    { id: 2, name: 'VoiceAI Assistant', price: 39.99 },
    { id: 3, name: 'ImageAI Enhancer', price: 29.99 },
    { id: 4, name: 'DataAI Analyzer', price: 59.99 },
  ];

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '2rem' }}>
      <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>AI Store</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>Browse and purchase our cutting-edge AI products.</p>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '2rem' }}>
        {products.map(product => (
          <div key={product.id} style={{ border: '1px solid #e0e0e0', borderRadius: '8px', padding: '1.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h3 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>{product.name}</h3>
            <p style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>${product.price.toFixed(2)}</p>
            <button style={{ backgroundColor: '#3498db', color: 'white', padding: '0.5rem 1rem', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>Add to Cart</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AIStore;
