import React, { useState } from 'react';

const Alfi = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim()) {
      setMessages([...messages, { text: input, sender: 'user' }]);
      setTimeout(() => {
        setMessages(msgs => [...msgs, { text: `You said: ${input}`, sender: 'ai' }]);
      }, 1000);
      setInput('');
    }
  };

  return (
    <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
      {isOpen ? (
        <div style={{ width: '300px', height: '400px', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', display: 'flex', flexDirection: 'column' }}>
          <div style={{ backgroundColor: '#3498db', color: 'white', padding: '10px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Chat with Alfi</span>
            <button onClick={() => setIsOpen(false)} style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer' }}>Close</button>
          </div>
          <div style={{ flex: 1, overflowY: 'auto', padding: '10px' }}>
            {messages.map((msg, index) => (
              <div key={index} style={{ marginBottom: '10px', textAlign: msg.sender === 'user' ? 'right' : 'left' }}>
                <span style={{ backgroundColor: msg.sender === 'user' ? '#3498db' : '#2ecc71', color: 'white', padding: '5px 10px', borderRadius: '10px', display: 'inline-block' }}>{msg.text}</span>
              </div>
            ))}
          </div>
          <div style={{ padding: '10px', borderTop: '1px solid #ccc' }}>
            <input type="text" value={input} onChange={(e) => setInput(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && handleSend()} placeholder="Type your message..." style={{ width: '100%', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} />
          </div>
        </div>
      ) : (
        <button onClick={() => setIsOpen(true)} style={{ backgroundColor: '#3498db', color: 'white', border: 'none', borderRadius: '50%', width: '60px', height: '60px', fontSize: '24px', cursor: 'pointer' }}>Alfi</button>
      )}
    </div>
  );
};

export default Alfi;
